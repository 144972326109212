import React from "react"
import { graphql, StaticQuery } from "gatsby"

// Pages
// import PerPageLayout from "../components/per-page-layout"
import LayoutV2 from "../components/v2/layout"
import SEO from "../components/seo"
import ContactForm from "../components/partials/forms/contact-form"

import "../utils/normalize.css"
import "../utils/css/v2.css"

// Partials
import {
  CardList,
  CardListItem,
  HeroTitleDiv,
} from "../components/partials/base"

// Content
const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <LayoutV2 location={location} title={siteTitle} headerActive={`Expertise`}>
      <SEO title={`Contact`} keywords={[]} />
      <article className="post-content page-template no-image animated fadeIn contact-page scroller-adjustment">
        <div className="post-content-body">
          <div>
            <HeroTitleDiv>
              <span
                className="p-color--psg-secondary"
                style={{ fontSize: `inherit` }}
              >
                Get in touch.
              </span>{" "}
              Let's work together.
            </HeroTitleDiv>
            <div className="row">
              <div className="col-sm-12">
                <CardList className={`contact-page-card-list`}>
                  <CardListItem cardTitle={`General`}>
                    <span>E: </span>
                    <a href="mailto:hello@prosourcebpo.com">
                      hello@prosourcebpo.com
                    </a>
                    <br />
                    <span> P: </span>{" "}
                    <a href="tel:+639568629802">+63 956 862 9802</a>
                  </CardListItem>
                  <CardListItem cardTitle={`New Distributed Teams`}>
                    <span> Steve Burns </span> <br />
                    <a href="tel:+639088209309">+63 908 820 9309</a> <br />
                    <span> E: </span>
                    <a href="mailto:sburns@prosourcebpo.com">
                      sburns@prosourcebpo.com
                    </a>
                  </CardListItem>
                  <CardListItem cardTitle={`Social`}>
                    <a
                      href="https://www.facebook.com/prosourcebpo/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>{" "}
                    <br />
                    <a
                      href="https://www.linkedin.com/company/prosource-global-corporation/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  </CardListItem>
                </CardList>
              </div>
            </div>
          </div>
          <div>
            <ContactForm className="contact-form" />
          </div>
        </div>
      </article>
    </LayoutV2>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage location={props.location} data={data} {...props} />
    )}
  />
)
