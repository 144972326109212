import React from "react"
import { navigate } from "gatsby"

import { FormGroupInput, FormGroupSubmit, FormGroupTextarea } from "../form"

import MaterialSnackbar from "../material/snackbar"

import APIRequest from "../../../utils/helpers/api"

export default class ContactForm extends React.Component {
  state = {
    name: "",
    email: "",
    work: "",
    number: "",
    showSnackbar: false,
    enableSubmit: false,
    submitMessageText: "Send Message",
  }

  validateForm = () => {
    const { name, email, work } = this.state
    let willEnableSubmit = true
    if (name.trim() === "") {
      willEnableSubmit = false
    }

    if (email.trim() === "") {
      willEnableSubmit = false
    }

    if (work.trim() === "") {
      willEnableSubmit = false
    }

    this.setState({
      enableSubmit: willEnableSubmit,
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const targetname = target.name

    this.setState({
      [targetname]: value,
    })

    this.validateForm()
  }

  handleSubmit = event => {
    event.preventDefault()
    const { name, email, work, number } = this.state

    this.setState({
      showSnackbar: false,
      submitMessageText: "Sending message...",
    })

    APIRequest.Post(
      {
        urlPath: "/contact/manage-form",
        reqBody: {
          name: name,
          email: email,
          body: work,
          number: number,
        },
      },
      (err, response) => {
        if (err) {
          console.log(err)
        } else {
          this.setState({
            showSnackbar: true,
            submitMessageText: "Message sent!",
          })

          setTimeout(() => {
            navigate("/")
          }, 2000)
        }
      }
    )
  }

  handleOnBlur = event => {
    this.validateForm()
  }

  render() {
    const { className } = this.props,
      { showSnackbar, enableSubmit, submitMessageText } = this.state

    return (
      <form
        onSubmit={this.handleSubmit}
        className={`prosource-form${className ? " " + className : ""}`}
      >
        <div className="form-label">Tell us about your project</div>
        <FormGroupInput
          labelName="Tell us your name"
          type="text"
          name="name"
          value={this.state.name}
          onChange={this.handleInputChange}
          onBlur={this.handleOnBlur}
        ></FormGroupInput>
        <FormGroupInput
          labelName="Tell us your email"
          type="text"
          name="email"
          value={this.state.email}
          onChange={this.handleInputChange}
          onBlur={this.handleOnBlur}
        ></FormGroupInput>
        <FormGroupInput
          labelName="Tell us your contact number"
          type="text"
          name="number"
          value={this.state.number}
          onChange={this.handleInputChange}
          onBlur={this.handleOnBlur}
        ></FormGroupInput>
        <FormGroupTextarea
          labelName="Tell us about the work"
          type="text"
          name="work"
          value={this.state.work}
          onChange={this.handleInputChange}
          rowCount={1}
          onBlur={this.handleOnBlur}
        ></FormGroupTextarea>
        <FormGroupSubmit
          className={`contact-submit-message`}
          disabled={!enableSubmit}
        >
          {submitMessageText}
        </FormGroupSubmit>
        {showSnackbar ? (
          <MaterialSnackbar
            open={true}
            variant={`success`}
            message={`Message sent!`}
          />
        ) : (
          <></>
        )}
      </form>
    )
  }
}
